import React, { Component } from 'react';
import logo from './logo.png';
import vodafoneLogo from './clients/vodafone.png';
import tescoLogo from './clients/tesco.png';
import hsbcLogo from './clients/hsbc.png';
import skyLogo from './clients/sky.png';
import phdvLogo from './clients/phdv.png';

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-logoWrapper">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <header className="App-header">
          <p>Function of X is a technology firm.</p>
          <p>We design and build remarkable software.</p>
        </header>
        <div className="App-mail">
          <p className="App-mail__start">How can we help you?</p>
          <p className="App-mail__email">solve@function-of-x.com</p>
        </div>
        <div className="App-clients">
          <p className="App-clients__start">Our team have worked with</p>
          <div className="App-clientLogoWrapper">
            <img src={vodafoneLogo} className="App-clientLogo" alt="logo" />
            <img src={tescoLogo} className="App-clientLogo" alt="logo" />
            <img src={hsbcLogo} className="App-clientLogo" alt="logo" />
            <img src={skyLogo} className="App-clientLogo" alt="logo" />
            <img src={phdvLogo} className="App-clientLogo" alt="logo" />
          </div>
        </div>

        <div className="App-footer">
          <a href="https://twitter.com/_functionofx" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter App-socialLogo" aria-hidden="true"></i>
          </a>
          <a href="https://www.linkedin.com/company/18358713" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-linkedin App-socialLogo" aria-hidden="true"></i>
          </a>
          <a href="https://github.com/function-of-x" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-github App-socialLogo" aria-hidden="true"></i>
          </a>
          <p className="App-footer_text">© {new Date().getFullYear()} FUNCTION OF X LIMITED</p>
        </div>
      </div>
    );
  }
}

export default App;
